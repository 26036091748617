const generateFeatureToggle = (name: string, isEnabled: boolean) => ({
  name,
  isEnabled,
})

const toggles = {
  EX_000: generateFeatureToggle(`[EX_000] Example toggle`, false),
} as const

export type ToggleName = keyof typeof toggles

export type Toggle = {
  name: ToggleName
  isEnabled: boolean
}

export type SimpleToggles = Partial<Record<ToggleName, boolean>>

export function getToggleStatesFromStorage(): SimpleToggles {
  const value = window.sessionStorage.getItem('featureToggles')
  if (value) {
    try {
      return JSON.parse(value)
    } catch (e) {
      return {}
    }
  }

  return {}
}

export function setToggleStatesToStorage(changedToggles: SimpleToggles) {
  const savedCustomToggles = getToggleStatesFromStorage()
  window.sessionStorage.setItem(
    'featureToggles',
    JSON.stringify({ ...savedCustomToggles, ...changedToggles }),
  )
  window.location.reload()
}

export function clearTogglesState() {
  window.sessionStorage.removeItem('featureToggles')
  window.location.reload()
}

const isToggle = (name: string): name is ToggleName => Object.hasOwn(toggles, name)

const loadToggleState = () => {
  const storedToggles = getToggleStatesFromStorage()

  for (const key in storedToggles) {
    if (isToggle(key)) {
      toggles[key].isEnabled = !!storedToggles[key]
    }
  }
}

loadToggleState()

export const FEATURE_TOGGLES = toggles
