import React from 'react'
import ReactDOM from 'react-dom'

type PortalProps = {
  prepend?: boolean
  className?: string
}

export default class Portal extends React.Component<PortalProps> {
  el: HTMLDivElement

  constructor(props: PortalProps) {
    super(props)
    this.el = document.createElement('div')
    if (props.className) {
      this.el.className = props.className
    }
  }

  componentDidMount() {
    if (this.props.prepend) {
      document.body.prepend(this.el)
    } else {
      document.body.append(this.el)
    }
  }

  componentDidUpdate() {
    if (this.props.className) {
      this.el.className = this.props.className
    }
  }

  componentWillUnmount() {
    document.body.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

export class PortalWithDOM extends React.Component<PortalProps> {
  el: HTMLDivElement

  state = {
    rendered: false,
  }

  constructor(props: PortalProps) {
    super(props)
    this.el = document.createElement('div')
    if (this.props.className) {
      this.el.className = this.props.className
    }
  }

  componentDidMount() {
    document.body.appendChild(this.el)
    this.setState({ rendered: true })
  }

  componentDidUpdate() {
    if (this.props.className) {
      this.el.className = this.props.className
    }
  }

  componentWillUnmount() {
    document.body.removeChild(this.el)
  }

  render() {
    if (!this.state.rendered) return null
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}
