import React, { FormEvent, useEffect } from 'react'
import Button, { SIZES, VARIANTS } from './components/Button'
import { CheckInlineBox } from './components/CheckInlineBox'
import Modal, { ModalHeader, ModalPadding } from './components/Modal'
import Portal from './components/portal'
import Title from './components/Title'
import {
  FEATURE_TOGGLES,
  SimpleToggles,
  ToggleName,
  clearTogglesState,
  setToggleStatesToStorage,
} from './feature-toggles'
import { useToggle } from './use-toggle'

export function FeatureTogglesEditor() {
  const [isOpen, toggle] = useToggle()

  useEffect(() => {
    window.openFeatureEditor = toggle
    return () => {
      window.openFeatureEditor = undefined
    }
  }, [toggle])

  if (!isOpen) return null

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    const data: SimpleToggles = {}
    const form = e.target as HTMLFormElement
    for (const key in FEATURE_TOGGLES) {
      // @ts-expect-error fuck elements
      const input = form.elements[key] as HTMLInputElement
      data[key as ToggleName] = input.checked
    }
    setToggleStatesToStorage(data)
    window.location.reload()
    e.preventDefault()
  }

  const onReset = () => {
    clearTogglesState()
    window.location.reload()
  }

  return (
    <Portal>
      <Modal onClose={toggle}>
        <ModalHeader>
          <Title>Features</Title>
        </ModalHeader>
        <ModalPadding>
          <form className="flex flex-col gap-4 items-start" onSubmit={onSubmit}>
            {Object.entries(FEATURE_TOGGLES).map(([key, { isEnabled, name }]) => (
              <CheckInlineBox name={key} key={key} defaultChecked={isEnabled}>
                {name}
              </CheckInlineBox>
            ))}
            <div className="flex gap-4">
              <Button variant={VARIANTS.PRIMARY} size={SIZES.SMALL}>
                Save
              </Button>
              <Button
                type="button"
                variant={VARIANTS.SECONDARY}
                size={SIZES.SMALL}
                onClick={onReset}
              >
                Reset
              </Button>
            </div>
          </form>
        </ModalPadding>
      </Modal>
    </Portal>
  )
}
