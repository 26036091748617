import { Trans } from '@lingui/macro'
import React, { FormEvent, MouseEvent, useState } from 'react'
import { useSetConsent } from './api/consent'
import Button, { VARIANTS } from './components/Button'
import Subtitle from './components/Subtitle'
import Switcher from './components/Switcher'
import Text from './components/Text'

export default function CookieBar() {
  const [isVisible, setIsVisible] = useState(!window._swp_consent?.consentStored)
  const [isExpanded, setIsExpanded] = useState(false)
  const { mutate } = useSetConsent()

  if (!isVisible) return null

  const onSuccess = () => setIsVisible(false)

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    mutate(new FormData(event.currentTarget), { onSuccess })
  }

  const onClickAll = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('ad_storage', 'granted')
    formData.append('analytics_storage', 'granted')
    formData.append('personalization_storage', 'granted')
    return mutate(formData, { onSuccess })
  }

  return (
    <form
      method="POST"
      action="/api/consent/"
      onSubmit={onSubmit}
      className="fixed bg-white right-0 left-0 bottom-0 justify-between items-center p-8 z-10 sh--primary md:flex"
    >
      <div className="space-y-4">
        <Subtitle>
          <Trans>Tyto stránky používají cookies</Trans>
        </Subtitle>
        <Text className="lg:m-w--58">
          <Trans>
            Cookies používáme, abychom zajistili správné fungování a bezpečnost našich stránek, tím
            pádem co nejlepší zkušenost při návštěvě. Kliknutím na „Přijmout všechny“ dáváte svůj
            souhlas s použitím cookies pro účely reklamy a analytik. Svá nastavení cookies můžete
            později kdykoliv změnit. Více informací naleznete v{' '}
            <a
              href="https://saywebpage.com/cs/zasady-ochrany-osobnich-udaju/"
              target="_blank"
              rel="noreferrer"
              className="A_link-text"
            >
              Prohlášení o cookies
            </a>
            .
          </Trans>
        </Text>
        {isExpanded && (
          <div className="space-x-4 flex flex-wrap items-center">
            <Switcher
              label={<Trans>Nezbytné</Trans>}
              name="functional_storage"
              value="granted"
              checked
              readOnly
              disabled
              required
            />
            <Switcher
              label={<Trans>Statistické</Trans>}
              name="analytics_storage"
              value="granted"
              defaultChecked={window._swp_consent?.consentAnalytics === 'granted'}
            />
            <Switcher
              label={<Trans>Marketingové</Trans>}
              name="ad_storage"
              value="granted"
              defaultChecked={window._swp_consent?.consentAds === 'granted'}
            />
            <Switcher
              label={<Trans>Personalizační</Trans>}
              name="personalization_storage"
              value="granted"
              defaultChecked={window._swp_consent?.consentPersonalization === 'granted'}
            />
          </div>
        )}
      </div>
      <div className="flex flex-wrap space-x-4 justify-end">
        {isExpanded ? (
          <Button variant={VARIANTS.SECONDARY} className="my-2" onClick={console.log}>
            <Trans>Přijmout zvolené</Trans>
          </Button>
        ) : (
          <Button
            variant={VARIANTS.SECONDARY}
            className="my-2"
            type="button"
            onClick={() => setTimeout(() => setIsExpanded(true), 0)}
          >
            <Trans>Specifické nastavení</Trans>
          </Button>
        )}
        <Button variant={VARIANTS.PRIMARY} className="my-2" type="button" onClick={onClickAll}>
          <Trans>Přijmout všechny</Trans>
        </Button>
      </div>
    </form>
  )
}
