// extracted by mini-css-extract-plugin
var _1 = "rU9XLLZu";
var _2 = "v6TZ4dFV";
var _3 = "oikW4xtS";
var _4 = "HeddOvZo";
var _5 = "cpGJtwmm";
var _6 = "s2sr11f7";
var _7 = "Yl9juEaR";
var _8 = "iVNgBNUL";
var _9 = "ARX04s7g";
var _a = "NsBmpqsk";
var _b = "zZkFfuRi";
var _c = "Www39AOa";
var _d = "No3GNqDy";
var _e = "B4Qu7Adi";
var _f = "TsSYDtOp";
var _10 = "Ry3mML0O";
var _11 = "oXju5QuD";
export { _1 as "active", _2 as "basic", _3 as "button", _4 as "danger", _5 as "done", _6 as "edit", _7 as "extra", _8 as "extrasmall", _9 as "isLoading", _a as "medium", _b as "primary", _c as "round", _d as "rounded", _e as "secondary", _f as "small", _10 as "tertiary", _11 as "tertiaryBasic" }
