import { Trans } from '@lingui/macro'
import * as React from 'react'
import { useMutation } from 'react-query'
import { useSnackbar, snackSuccess } from '../snackbar'
import { post } from './fetch'
import { makeError } from './make-error'

const SET = 'api/consent'

export function useSetConsent() {
  const snack = useSnackbar()
  return useMutation((payload: FormData) => post(`/${SET}/`, payload), {
    onSuccess: () => {
      snack(snackSuccess(<Trans>Změny uloženy</Trans>))
    },
    onError: () => {
      makeError(snack, <Trans>Nepodařilo se uložit změny</Trans>)()
    },
  })
}
