import { cx } from '@emotion/css'
import React from 'react'
import * as css from './Text.module.less'

const sizes = {
  small: 'text-12-14',
  default: 'text-14-15',
  large: 'text-14-16',
}

type SizeT = keyof typeof sizes

const colors = {
  primary: 'text-primary',
  dark: 'text-dark',
  default: 'text-dark-black',
  error: 'text-red-600',
  danger: 'text-orange',
  inverse: 'text-white',
}

type ColorT = keyof typeof colors

type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode
  className?: string
  color?: ColorT
  elementClassName?: string
  style?: Record<string, string | number>
  size?: SizeT
}

export default function Text({
  style,
  children,
  className,
  elementClassName = '',
  size = 'default',
  color = 'default',
  ...props
}: Props) {
  return (
    <div className={cx(css.box, className, sizes[size], colors[color])} {...props}>
      <p className={`inline-block ${elementClassName}`} style={style}>
        {children}
      </p>
    </div>
  )
}
