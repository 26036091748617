import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'

type MetasProps = {
  viewport?: string
  children?: ReactNode
}

export default function Metas({
  viewport = `width=device-width,initial-scale=1.0`,
  children,
}: MetasProps) {
  return (
    <Helmet titleTemplate="%s | Saywebpage" defaultTitle="Saywebpage">
      <meta name="viewport" content={viewport} />
      {children}
    </Helmet>
  )
}
