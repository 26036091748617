import { Trans } from '@lingui/macro'
import React from 'react'
import * as css from './shadow-login.module.less'
import { useUnsafeUser } from './use-user'

export default function ShadowLogin() {
  const user = useUnsafeUser()

  if (!user || !user.shadow) {
    return null
  }

  return (
    <div className={css.shadow}>
      <Trans>Pozor, jste přihlášen jako jiný uživatel</Trans>
    </div>
  )
}
