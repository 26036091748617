import { cx } from '@emotion/css'
import React from 'react'
import * as css from './Title.module.less'

const SIZES = {
  small: 'text-18-24',
  default: 'text-20-32',
  large: 'text-22-38',
}

type TitleProps = {
  className?: string
  elementClassName?: string
  size?: 'small' | 'large' | 'default'
} & React.HTMLAttributes<HTMLHeadingElement>

export default function Title({
  className,
  elementClassName = '',
  size = 'default',
  ...props
}: TitleProps) {
  return (
    <div className={cx(css.box, className, SIZES[size])}>
      <h2 className={`inline-block ${elementClassName}`} {...props} />
    </div>
  )
}
