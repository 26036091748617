import { cx } from '@emotion/css'
import React from 'react'
import * as css from './CheckInlineBox.module.less'

type CheckInlineBoxProps = {
  children: React.ReactNode
  boxClassName?: string
} & React.InputHTMLAttributes<HTMLInputElement>

export function CheckInlineBox({
  children,
  className,
  boxClassName = '',
  ...props
}: CheckInlineBoxProps) {
  return (
    <label className={`flex items-center cursor-pointer ${css.box} ${boxClassName}`}>
      <input className={css.input} type="checkbox" {...props} />
      <span className={cx('mr-4', css.checkbox, className, props.checked && css.checked)} />
      {children}
    </label>
  )
}
