import React, { Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Error from './components/error'
import ErrorBoundary from './components/error-boundary'
import Loading from './components/loading'
import Metas from './components/metas'
import CookieBar from './cookie-bar'
import { FeatureTogglesEditor } from './feature-toggles-editor'
import LimitedOffer from './LimitedOffer'
import * as Routes from './Routes'
import { ScrollToTop } from './scroll-to-top'
import ShadowLogin from './shadow-login'
import { useUnsafeUser } from './use-user'
import useErrorLogging from './useErrorLogging'

export default function App() {
  useErrorLogging()

  const user = useUnsafeUser()
  const role = user?.role

  return (
    <ErrorBoundary onError={Error}>
      <HelmetProvider>
        <Metas />
        {user ? (
          <>
            <ShadowLogin />
            <LimitedOffer />
          </>
        ) : null}
        <FeatureTogglesEditor />
        <Suspense fallback={<Loading />}>
          <BrowserRouter>
            <ScrollToTop />
            <Switch>
              <Route path="/admin/:siteId(\d+)/edit/:contentId?" exact component={Routes.Edit} />
              <Route path="/admin/:siteId(\d+)/extend" component={Routes.PlanExtend} />
              <Route path="/admin/:siteId(\d+)/plan-upgrade" component={Routes.PackageUpgrade} />
              <Route path="/admin/:siteId(\d+)/order/return" component={Routes.OrderReturn} />
              <Route path="/admin/:siteId(\d+)/order" component={Routes.Order} />
              <Route path="/admin/:siteId(\d+)/invoice" component={Routes.Order} />
              <Route
                path="/admin/:siteId(\d+)/preview/json/:contentId?"
                component={Routes.Preview}
              />
              <Route path="/admin/:siteId(\d+)/preview/:contentId?" component={Routes.Preview} />
              <Route path="/admin/:siteId(\d+)/redirects" component={Routes.Redirects} />
              <Route path="/admin/:siteId(\d+)/site-settings" component={Routes.SiteSettings} />
              <Route path="/admin/:siteId(\d+)/site-snippets" component={Routes.SiteSnippets} />
              <Route path="/admin/:siteId(\d+)/vouchers" component={Routes.Vouchers} />
              <Route path="/admin/:siteId(\d+)/theme" component={Routes.Theme} />
              <Route path="/admin/order/return" component={Routes.OrderReturn} />
              <Route path="/admin/invoice/return" component={Routes.OrderReturn} />
              <Route path="/admin/my-orders" component={Routes.MyOrders} />
              <Route path="/admin/preview" component={Routes.Preview} />
              <Route path="/admin/referral" component={Routes.Referral} />
              <Route path="/admin/theme" component={Routes.Theme} />
              <Route path="/admin/user-settings" component={Routes.UserSettings} />
              {role === 10 && <Route path="/admin/analytics" component={Routes.Analytics} />}
              {role === 10 && <Route path="/admin/manager" component={Routes.Manager} />}
              {role === 10 && <Route path="/admin/orders" component={Routes.MainManager} />}
              {role === 10 && <Route path="/admin/promocodes" component={Routes.MainManager} />}
              {role === 10 && <Route path="/admin/sites" component={Routes.MainManager} />}
              {role === 10 && <Route path="/admin/theme-manager" component={Routes.ThemeManager} />}
              {role === 10 && <Route path="/admin/tools" component={Routes.Tools} />}
              {role === 10 && <Route path="/admin/users" component={Routes.MainManager} />}
              <Route path="/api/screenshot/:contentId" component={Routes.Preview} />
              <Route path="/admin" component={Routes.Homepage} />
              <Route path="/sign" component={Routes.Sign} />
              <Route path="/email" component={Routes.Email} />
            </Switch>
          </BrowserRouter>
        </Suspense>
        <CookieBar />
      </HelmetProvider>
    </ErrorBoundary>
  )
}
