import * as Sentry from '@sentry/browser'
import { useEffect } from 'react'
import { post } from './api/fetch'

const chunks: string[] = []

type ErrorType = Error & {
  code?: string
}

export function log(error: ErrorType | string = 'No message', componentStack?: string) {
  if (typeof error === 'string') {
    error = new Error(error)
  } else if (error?.message?.includes('Loading chunk')) {
    const [chunk] = error.message.match(/(\d+)/) || ['']
    if (chunks.includes(chunk)) {
      return
    }
    chunks.push(chunk)
  }

  const message = error.message
  const code = error.code ?? 'NO_CODE'

  let trace = error.stack ?? ''
  if (componentStack) {
    trace += '\n' + componentStack
  }

  const extra = {
    location: window.location.href,
    user: window.__USER__,
  }
  const payload = { message, code, trace, extra }
  Sentry.captureException(error)
  post('/api/error-logging', payload).catch(() => null)
}

export default function useErrorLogging() {
  useEffect(() => {
    window.addEventListener('unhandledrejection', event => {
      if (event.reason) {
        if (!process.env.LOG_ERRORS) return
        log(event.reason)
      }
    })
    window.addEventListener('error', event => {
      if (event.error) {
        if (!process.env.LOG_ERRORS) return
        log(event.error)
      }
    })
  }, [])
}
