import { i18n } from '@lingui/core'
import { useLingui } from '@lingui/react'
import { CurrencyType } from './api/currencies'
import { LocaleT } from './types/entities'

export type LocaleDataT = {
  name: string
  country: string
  currency: CurrencyType['iso']
  domain: string
}

export const localesData: Record<LocaleT, LocaleDataT> = {
  cs: {
    name: 'Čeština',
    country: 'CZE',
    currency: 'CZK',
    domain: '.cz',
  },
  en: {
    name: 'English',
    country: 'USA',
    currency: 'EUR',
    domain: '.com',
  },
  hr: {
    name: 'Hrvatski',
    country: 'HRV',
    currency: 'EUR',
    domain: '.eu',
  },
  de: {
    name: 'Deutsch',
    country: 'DEU',
    currency: 'EUR',
    domain: '.de',
  },
  pl: {
    name: 'Polskie',
    country: 'POL',
    currency: 'EUR',
    domain: '.eu',
  },
  es: {
    name: 'Español',
    country: 'ESP',
    currency: 'EUR',
    domain: '.com',
  },
}

export const locales = Object.keys(localesData) as LocaleT[]

export const loadCatalog = async function (language: LocaleT) {
  return import(
    /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
    `./locales/${language}/messages.mjs`
  ).then(catalog => {
    i18n.load(language, catalog.messages)
    i18n.activate(language)
  })
}

const changeLanguage = loadCatalog

export const useLanguage = function () {
  const { i18n } = useLingui()
  return [changeLanguage, i18n] as const
}
