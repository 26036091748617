import { Trans } from '@lingui/macro'
import React from 'react'
import Button, { VARIANTS } from './Button'
import Icon from './error.svg'
import Modal from './Modal'
import Subtitle from './Subtitle'
import Text from './Text'

export const ERRORS = {
  LOADING_CHUNK_FAILED: 'LOADING_CHUNK_FAILED',
  GENERIC: 'GENERIC',
} as const

type ErrorProps = {
  errorCode: string
  retry: VoidFunction
}

export default function Error(props: ErrorProps) {
  switch (props.errorCode) {
    case ERRORS.LOADING_CHUNK_FAILED:
      props.retry()
      return (
        <Modal className="modal__wrap--slim text-center">
          <Icon width="100" />
          <Subtitle className="mt--20 text-22">
            <Trans>Nová verze aplikace</Trans>
          </Subtitle>
          <Text className="mt--20 text-gray-600">
            <Trans>
              Omlouváme se, vylepšovali jsme naší aplikaci a je tak nutné obnovit stránku, abyste
              mohli pokračovat.
            </Trans>
          </Text>
          <Button variant={VARIANTS.PRIMARY} className="mt--30" onClick={props.retry}>
            <Trans>Obnovit stránku</Trans>
          </Button>
        </Modal>
      )
    default:
      return (
        <Modal className="modal__wrap--slim text-center">
          <Icon width="100" />
          <Subtitle className="mt--20 text-22">
            <Trans>Nastala chyba</Trans>
          </Subtitle>
          <Text className="mt--20 text-gray-600">
            <Trans>Došlo k chybě opakovaně? Kontaktujte prosím podporu.</Trans>&nbsp;
            support@saywebpage.com
          </Text>
          <Button variant={VARIANTS.PRIMARY} className="mt--30" onClick={props.retry}>
            <Trans>Zkusit znovu</Trans>
          </Button>
        </Modal>
      )
  }
}
