import React, { ComponentType, ErrorInfo } from 'react'
import { log } from '../useErrorLogging'
import { ERRORS } from './error'

type ErrorBoundaryProps = {
  onError: ComponentType<{
    errorCode: string
    retry: VoidFunction
  }>
}

type ErrorBoundaryState =
  | {
      hasError: false
      errorCode: null
    }
  | {
      hasError: true
      errorCode: string
    }

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false, errorCode: null }

  static getDerivedStateFromError(error: Error & { code?: string }) {
    let errorCode: string = ERRORS.GENERIC
    if (error.name === 'ChunkLoadError' || error.code === 'CSS_CHUNK_LOAD_FAILED') {
      errorCode = ERRORS.LOADING_CHUNK_FAILED
    }
    return { hasError: true, errorCode }
  }

  componentDidCatch(error: Error & { code?: string }, errorInfo: ErrorInfo) {
    switch (this.state.errorCode) {
      case ERRORS.LOADING_CHUNK_FAILED:
        // not logged, we reload the window
        break
      default:
        log(error, errorInfo.componentStack)
        break
    }
  }

  render() {
    if (this.state.hasError) {
      const ErrorComponent = this.props.onError
      return (
        <ErrorComponent errorCode={this.state.errorCode} retry={() => window.location.reload()} />
      )
    }

    return this.props.children
  }
}
