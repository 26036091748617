import { cx } from '@emotion/css'
import React, { forwardRef } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import * as css from './Button.module.less'
import Loading from './loading'

const sizes = {
  MEDIUM: css.medium,
  SMALL: css.small,
  EXTRASMALL: css.extrasmall,
  DONE: css.done,
}

export const enum SIZES {
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  EXTRASMALL = 'EXTRASMALL',
  DONE = 'DONE',
}

const variants = {
  PRIMARY: `${css.primary} bg-primary text-white dark:shadow-none dark:hover:shadow-none`,
  SECONDARY: `${css.secondary} dark:bg-dark-gray-light dark:text-white dark:shadow-none dark:hover:brightness-125 dark:focus:secondary-btn`,
  TERTIARY: `${css.tertiary} dark:hover:bg-dark-gray-light dark:shadow-none dark:hover:shadow-none`,
  EXTRA: `${css.extra} bg-extra text-white`,
  DANGER: css.danger,
  BASIC: css.basic,
  TERTIARYBASIC: `${css.tertiaryBasic} dark:text-white`,
  DONE: `${css.done} bg-primary text-white dark:hover:shadow-none`,
  EDIT: `${css.edit} dark:text-white dark:shadow-none dark:hover:bg-dark-gray-light dark:hover:text-white`,
}

export const enum VARIANTS {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  EXTRA = 'EXTRA',
  DANGER = 'DANGER',
  BASIC = 'BASIC',
  TERTIARYBASIC = 'TERTIARYBASIC',
  DONE = 'DONE',
  EDIT = 'EDIT',
}

const shapes = {
  ROUNDED: css.rounded,
  ROUND: css.round,
}

export const enum SHAPES {
  ROUNDED = 'ROUNDED',
  ROUND = 'ROUND',
}

export type ButtonProps = React.HTMLAttributes<HTMLElement> &
  Readonly<{
    active?: boolean
    activeClassName?: string
    as?: React.ElementType
    children: React.ReactNode
    className?: string
    disabled?: boolean
    exact?: boolean
    form?: string
    href?: string
    isLoading?: boolean
    name?: string
    shape?: SHAPES
    size?: SIZES
    target?: string
    to?: LinkProps['to']
    type?: string
    value?: string
    variant?: VARIANTS
  }>

export default forwardRef<HTMLElement, ButtonProps>(function Button(
  {
    as,
    children,
    className,
    isLoading,
    shape = SHAPES.ROUNDED,
    size,
    variant,
    active,
    ...props
  }: ButtonProps,
  ref,
) {
  const Component = as ?? (props.href ? 'a' : props.to ? Link : 'button')
  return (
    <Component
      ref={ref}
      className={cx(
        'box-border cursor-pointer',
        className,
        css.button,
        active && css.active,
        isLoading && css.isLoading,
        size && sizes[size],
        variant && variants[variant],
        shapes[shape],
      )}
      {...props}
    >
      {children}
      {isLoading && <Loading size="small" overlay />}
    </Component>
  )
})
