import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import App from './app'
import { SnackbarProvider } from './snackbar'
import { UserProvider } from './use-user'
import { loadCatalog } from './useLanguage'
import './css/style.less'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://8ab7fa34ccca484e9a69d98551052d46@o4504810962878464.ingest.sentry.io/4504810964123648',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })

  if (window.__USER__) {
    Sentry.configureScope(scope => {
      scope.setUser({ id: window.__USER__.id.toString() })
    })
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

queryClient.setQueryDefaults(['edit', 'init'], {
  cacheTime: 0,
  staleTime: Infinity,
  keepPreviousData: false,
  refetchOnMount: true,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
})

function I18nApp() {
  return (
    <React.StrictMode>
      <UserProvider>
        <I18nProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools />
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </QueryClientProvider>
        </I18nProvider>
      </UserProvider>
    </React.StrictMode>
  )
}

const language = window.LANGUAGE || 'cs'
loadCatalog(language).then(() => {
  ReactDOM.render(<I18nApp />, document.getElementById('app'))
})
