import { lazy } from 'react'

export const Analytics = lazy(() => import(/* webpackChunkName: "r-analytics" */ './Analytics'))
export const Edit = lazy(() => import(/* webpackChunkName: "r-edit" */ './Edit'))
export const Email = lazy(() => import(/* webpackChunkName: "r-email" */ './Email'))
export const Homepage = lazy(() => import(/* webpackChunkName: "r-homepage" */ './Homepage'))
export const MainManager = lazy(
  () => import(/* webpackChunkName: "r-main-manager" */ './main-manager'),
)
export const Manager = lazy(() => import(/* webpackChunkName: "r-manager" */ './Manager'))
export const MyOrders = lazy(() => import(/* webpackChunkName: "r-my-orders" */ './MyOrders'))
export const Order = lazy(() => import(/* webpackChunkName: "r-order" */ './Order'))
export const OrderReturn = lazy(() => import(/* webpackChunkName: "r-order" */ './Order/return'))
export const PackageUpgrade = lazy(() => import(/* webpackChunkName: "r-order" */ './plan-upgrade'))
export const PlanExtend = lazy(() => import(/* webpackChunkName: "r-order" */ './PlanExtend'))
export const Preview = lazy(() => import(/* webpackChunkName: "r-edit" */ './Preview'))
export const Redirects = lazy(() => import(/* webpackChunkName: "r-site-settings" */ './Redirects'))
export const Referral = lazy(() => import(/* webpackChunkName: "r-referral" */ './Referral'))
export const Sign = lazy(() => import(/* webpackChunkName: "r-sign" */ './Sign'))
export const SiteSettings = lazy(
  () => import(/* webpackChunkName: "r-site-settings" */ './SiteSettings'),
)
export const SiteSnippets = lazy(
  () => import(/* webpackChunkName: "r-site-settings" */ './SiteSnippets'),
)
export const Theme = lazy(() => import(/* webpackChunkName: "r-theme" */ './Theme'))
export const ThemeManager = lazy(() => import(/* webpackChunkName: "r-manager" */ './ThemeManager'))
export const Tools = lazy(() => import(/* webpackChunkName: "r-manager" */ './Tools'))
export const UserSettings = lazy(
  () => import(/* webpackChunkName: "r-user-settings" */ './UserSettings'),
)
export const Vouchers = lazy(() => import(/* webpackChunkName: "r-vouchers" */ './Vouchers'))
