type ValidationErrorFieldT =
  | {
      name: string
      error?: string
    }
  | {
      code: string
      path: Array<string>
    }

export class ValidationError<ResponseT = unknown> extends Error {
  fields: Record<string, string | boolean>
  response: ResponseT | undefined

  constructor(fields: ValidationErrorFieldT[], response?: ResponseT) {
    super('Validation error')

    this.fields = {}
    for (const field of fields) {
      if ('name' in field) {
        this.fields[field.name] = field.error ?? true
      } else if (field.path) {
        this.fields[field.path[0]] = field.code
      }
    }

    this.response = response
  }
}

export class RequestError<ResponseT = unknown> extends Error {
  statusCode: number
  response: ResponseT | undefined

  constructor(statusCode: number, statusText: string, response?: ResponseT) {
    super(statusText)
    this.statusCode = statusCode
    this.response = response
  }
}

// Server and Client error can be used for displaying generic alerts and
// differentiate between the server being at fault and the request being wrong
export class ServerError<ResponseT = unknown> extends RequestError<ResponseT> {}
export class ClientError<ResponseT = unknown> extends RequestError<ResponseT> {}

export type ApiErrorType<ResponseT = unknown> =
  | ValidationError<ResponseT>
  | ServerError<ResponseT>
  | ClientError<ResponseT>

type ResponseErrorT = {
  error: Record<string, string | boolean> | { message: string } | string
}

function isResponseError(response: unknown): response is ResponseErrorT {
  if (!response) {
    return false
  }
  return Object.prototype.hasOwnProperty.call(response, 'error')
}

export default async function simpleFetch<Type>(
  url: string,
  payload?: unknown,
  method = 'POST',
  options?: RequestInit,
): Promise<Type> {
  let body: string | FormData | undefined
  let headers: Record<string, string> | undefined

  if (payload instanceof FormData) {
    body = payload
  } else if (typeof payload === 'string') {
    body = payload
    headers = { 'Content-Type': 'application/json' }
  } else if (payload) {
    body = JSON.stringify(payload)
    headers = { 'Content-Type': 'application/json' }
  }

  const response = await fetch(url, {
    credentials: 'same-origin',
    method,
    headers,
    body,
    ...options,
  })

  let data: string | Type | ResponseErrorT

  if (response.headers.get('Content-Type')?.indexOf('json') === -1) {
    data = await response.text()
  } else {
    data = (await response.json()) as Type | ResponseErrorT

    if (isResponseError(data) && Array.isArray(data.error)) {
      throw new ValidationError(data.error, data)
    }
  }

  if (response.status >= 500) {
    throw new ServerError(response.status, response.statusText)
  }

  if (response.status >= 400) {
    throw new ClientError(response.status, response.statusText)
  }

  if (isResponseError(data)) {
    let statusText = 'Error'
    if (typeof data.error === 'string') {
      statusText = data.error
    } else if (typeof data.error.message === 'string') {
      statusText = data.error.message
    }
    throw new ClientError(response.status, statusText || 'Error', data)
  }

  return data as Type
}

export function get<T>(url: string, searchParams?: URLSearchParams): Promise<T> {
  if (searchParams) {
    const search = searchParams.toString()
    if (search) {
      url += '?' + search
    }
  }
  return simpleFetch<T>(url, null, 'GET')
}

export function post<T, P = unknown>(url: string, payload?: P, options?: RequestInit): Promise<T> {
  return simpleFetch<T>(url, payload, 'POST', options)
}
