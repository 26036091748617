import { cx } from '@emotion/css'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import React, { forwardRef, useState, useCallback } from 'react'

import * as css from './Modal.module.less'

export const SIZE = {
  XS: 'lg:m-w--30',
  S: 'lg:m-w--35',
  M: 'lg:m-w--47',
  L: 'lg:m-w--58',
}

type ModalProps = Readonly<{
  label?: string
  onClose?: () => void
  stretch?: boolean
  size?: string
  spacing?: string | true
  className?: string | (string | boolean | null | undefined)[] | Record<string, boolean>
  children: React.ReactNode
}>

export default forwardRef<HTMLDivElement, ModalProps>(function Modal(
  { onClose, stretch, size, spacing = 'p-4 sm:p-12', className, label, ...props },
  ref,
) {
  return (
    <DialogOverlay
      isOpen
      className={`${css.dialogOverlay} overflow-y-scroll z-10 scale-box`}
      onDismiss={onClose}
    >
      <div
        className={cx(
          'lg:px-14 lg:py-8 box-border flex justify-center w-full min-h-full items-stretch',
          stretch ? null : 'md:items-center',
        )}
        ref={ref}
      >
        <DialogContent
          aria-label={label}
          className={cx(
            css.dialogContent,
            'box-border w-full lg:rounded relative m-auto m-w--75 bg-white',
            size,
            spacing,
            className,
          )}
          {...props}
        />
      </div>
    </DialogOverlay>
  )
})

type ModalHeaderProps = {
  children: React.ReactNode
  className?: string
  small?: boolean
}

export function ModalHeader({ children, className, small }: ModalHeaderProps) {
  const [isStuck, setIsStuck] = useState(false)
  const [intersectionObserver] = useState(
    () =>
      new IntersectionObserver(
        ([entry]: IntersectionObserverEntry[]) => {
          setIsStuck(!entry.isIntersecting)
        },
        {
          rootMargin: '-1px 0px 0px 0px',
          threshold: [1],
        },
      ),
  )
  const ref = useCallback(
    (element: HTMLElement) => {
      if (element) {
        intersectionObserver.observe(element)
      } else {
        intersectionObserver.disconnect()
      }
    },
    [intersectionObserver],
  )

  return (
    <header
      ref={ref}
      className={cx(
        'scale-box sticky top-0 bg-inherit z-2 py-3 rounded-inherit',
        small ? 'px-6 sm:px-8 sm:py-4' : 'px-4 sm:px-10 sm:py-6',
        isStuck ? 'border-solid border-b-1 border-gray-200 sh-a' : null,
        className,
      )}
    >
      {children}
    </header>
  )
}

type ModalPadding = {
  children: React.ReactNode
  className?: string
  small?: boolean
}

export function ModalPadding({ children, className, small }: ModalPadding) {
  return (
    <div className={cx('scale-box', small ? 'py-2 px-6 sm:px-8' : 'px-4 py-3 sm:px-10', className)}>
      {children}
    </div>
  )
}
