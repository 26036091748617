import React, { useContext, createContext, ReactNode } from 'react'

export type UserType = {
  id: number
  email: string
  role: 1 | 10
  toggles: Record<string, boolean>
  language: string
  picture?: string
  shadow?: boolean
  userHashId?: string
}

export const UserContext = createContext<UserType | null>(null)

type Props = {
  children: ReactNode
}

export function UserProvider({ children }: Props) {
  return <UserContext.Provider value={window.__USER__ || null}>{children}</UserContext.Provider>
}

export function useUser(): UserType {
  const user = useContext(UserContext)

  if (!user) {
    throw new Error('Cannot access user')
  }

  return user
}

export function useUnsafeUser(): UserType | null {
  return useContext(UserContext)
}
