import { Trans } from '@lingui/macro'
import React from 'react'
import { useLimitedOffer, useActivateLimitedOffer } from './api/limited-offer'
import Button, { SIZES } from './components/Button'
import { useToggle } from './use-toggle'

export default function LimitedOffer() {
  const { data } = useLimitedOffer()
  const activate = useActivateLimitedOffer()
  const [dismissed, toggle] = useToggle()

  if (dismissed) return null

  if (!data) return null

  if (!data.eligible) return null

  if (data.activated) {
    return (
      <div className="bg-primary text-white p-2 flex gap-4 items-center justify-between">
        <div className="m-auto">
          <Trans>Při objednávce dnes ušetříš 20 % – aktivní.</Trans>
        </div>
        <Button size={SIZES.SMALL} onClick={toggle}>
          Zavřít
        </Button>
      </div>
    )
  }

  return (
    <div
      className="bg-primary text-white p-2 flex gap-4 items-center justify-between"
      onClick={activate}
    >
      <div className="m-auto">
        <Trans>Při objednávce dnes ušetříš 20 % - aktuvijte slevu kliknutím zde.</Trans>
      </div>
      <Button size={SIZES.SMALL} onClick={toggle}>
        Zavřít
      </Button>
    </div>
  )
}
