import { useReducer } from 'react'

export function useToggle(initialState = false): [boolean, (next?: unknown) => void] {
  return useReducer((state: boolean, action?: unknown) => {
    if (typeof action == 'boolean') {
      return action
    }
    return !state
  }, initialState)
}
