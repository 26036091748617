import React, { createContext, useReducer, useEffect, Dispatch, ReactNode } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Portal from './components/portal'

let inc = 0

enum ACTIONS {
  OPEN,
  CLOSE,
}

type Type = 'success' | 'error'

type SnackType = {
  id: string
  type: Type
  timeout?: number
  content: ReactNode
}

type SnackActionType =
  | {
      type: ACTIONS.OPEN
      payload: {
        id?: string
        type: Type
        timeout?: number
        content: ReactNode
      }
    }
  | {
      type: ACTIONS.CLOSE
      id: string
    }

export type DispatchType = Dispatch<SnackActionType>

export const SnackbarContext = createContext<DispatchType | null>(null)

function reducer(state: Array<SnackType>, action: SnackActionType): Array<SnackType> {
  switch (action.type) {
    case ACTIONS.OPEN: {
      const { id = `__${inc++}`, type, timeout, content } = action.payload
      return state.concat([{ id, type, timeout, content }])
    }
    case ACTIONS.CLOSE:
      return state.filter(item => item.id !== action.id)
  }
}

type Props = {
  children: ReactNode
}
export function SnackbarProvider({ children }: Props) {
  const [list, dispatch] = useReducer(reducer, [])

  return (
    <SnackbarContext.Provider value={dispatch}>
      {children}
      <Snackbar list={list} dispatch={dispatch} />
    </SnackbarContext.Provider>
  )
}

type SnackbarProps = {
  list: SnackType[]
  dispatch: DispatchType
}
export function Snackbar({ list, dispatch }: SnackbarProps) {
  return (
    <Portal>
      <TransitionGroup appear className="snackbar">
        {list.map(item => (
          <CSSTransition key={item.id} timeout={500} classNames="snackbar__snack">
            <Snack dispatch={dispatch} {...item} />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Portal>
  )
}

type SnackProps = SnackType & {
  dispatch: DispatchType
}

function Snack({ id, type, timeout, content, dispatch }: SnackProps) {
  useEffect(() => {
    if (!timeout) return

    const timer = window.setTimeout(() => {
      dispatch(snackClose(id))
    }, timeout)
    return () => window.clearTimeout(timer)
  }, [id, dispatch, timeout])

  return <div className={`snackbar__snack snackbar__snack--${type}`}>{content}</div>
}

export function useSnackbar() {
  const dispatch = React.useContext(SnackbarContext)
  if (!dispatch) {
    throw new Error('Cannot use SnackbarContext')
  }
  return dispatch
}

export function snackClose(id: string): SnackActionType {
  return {
    type: ACTIONS.CLOSE,
    id,
  }
}

export function snackSuccess(content: React.ReactNode): SnackActionType {
  return {
    type: ACTIONS.OPEN,
    payload: {
      type: 'success',
      timeout: 3000,
      content,
    },
  }
}

export function snackError(content: ReactNode): SnackActionType {
  return {
    type: ACTIONS.OPEN,
    payload: {
      type: 'error',
      timeout: 10000,
      content,
    },
  }
}
