import { useQuery, useMutation, useQueryClient } from 'react-query'
import { get, post } from './fetch'

const GET = 'api/limited-offer'
const GET_ACTIVE = 'api/limited-offer/get-active'
const ACTIVATE = 'api/limited-offer/activate'

type LimitedOfferT = {
  eligible: boolean
  eligibleUntil?: number
  activated: boolean
} | null

export function useLimitedOffer() {
  const limitedOffer = useQuery<LimitedOfferT>([GET], () => get(`/${GET}`), {
    staleTime: 600e3,
    cacheTime: 600e3,
    refetchOnMount: false,
  })
  return limitedOffer
}

export function useActivateLimitedOffer() {
  const queryClient = useQueryClient()
  const { mutate } = useMutation<LimitedOfferT, Error, never>(() => post(`/${ACTIVATE}`), {
    onSuccess() {
      queryClient.setQueryData<LimitedOfferT>([GET], (data?: LimitedOfferT): LimitedOfferT => {
        if (!data) {
          return { eligible: false, activated: false }
        }
        return { ...data, activated: true }
      })
      queryClient.invalidateQueries([GET_ACTIVE])
    },
  })
  return mutate
}

export function useGetActiveCode() {
  return useQuery([GET_ACTIVE], () => get<string>(`/${GET_ACTIVE}`))
}
