import { cx } from '@emotion/css'
import React from 'react'
import Spinner from './spinner.svg'

const SIZES = {
  default: 32,
  small: 24,
  text: 16,
}

type Props = {
  inline?: boolean
  overlay?: boolean
  size?: keyof typeof SIZES
}

export default function Loading({ inline = false, overlay = false, size = 'default' }: Props) {
  return (
    <div
      className={cx(
        {
          A_admin__loading__cover: !inline,
          absolute: overlay,
        },
        'flex items-center justify-center',
      )}
    >
      <Spinner width={SIZES[size]} height={SIZES[size]} />
    </div>
  )
}
