import { cx } from '@emotion/css'
import React from 'react'

export interface SwitcherProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode
  smaller?: boolean
  isTouched?: boolean
}

export default function Switcher({
  className = '',
  label,
  smaller,
  isTouched,
  ...props
}: SwitcherProps) {
  return (
    <label className={`c-switcher ${className}`}>
      {label}
      <span
        className={cx(
          'c-switcher__input',
          smaller ? 'c-switcher__input--smaller' : '',
          isTouched ? 'c-switcher__input--touched' : '',
        )}
      >
        <input type="checkbox" {...props} />
        <span
          className={cx(
            'c-switcher__slider',
            'c-switcher__slider--round',
            smaller ? 'c-switcher__slider--smaller' : '',
          )}
        />
      </span>
    </label>
  )
}
