import { cx } from '@emotion/css'
import React, { forwardRef } from 'react'
import * as css from './Subtitle.module.less'

const sizes = {
  largest: 'text-26-28',
  large: 'text-18-24',
  small: 'text-16-19',
  default: 'text-18-21',
  none: '',
}

type SizeT = keyof typeof sizes

type Props = Readonly<{
  className?: string
  children: React.ReactNode
  elementClassName?: string
  size?: SizeT
}> &
  React.HTMLAttributes<HTMLHeadingElement>

export default forwardRef<HTMLDivElement, Props>(function Subtitle(
  { className, elementClassName = '', size = 'default', ...props },
  ref,
) {
  return (
    <div className={cx(css.box, className, sizes[size])} ref={ref}>
      <h3 className={`inline-block ${elementClassName}`} {...props}></h3>
    </div>
  )
})
